.display-flex {
  display: flex;
}
.d-flex-column {
  flex-direction: column;
}
.d-flex-center {
  justify-content: center;
  align-items: center;
}
.text-align-center {
  text-align: center;
}
.gap {
  gap: 2%;
}

.header-text {
  font-size: 3vmin;
  text-align: center;
}
.text-danger {
  color: #dc3545 !important;
}
.text-align-justify {
  text-align: justify;
}
.navbar {
  /* background-color: #212529; */
  background-color: #082c44;
}
.nav-link {
  color: #f8f9fa;
}
.nav-link:hover {
  color: #00bbf9;
}
.nav-link.active {
  background-color: #00bbf9 !important;
}
.nav-link.active:hover {
  color: #f8f9fa;
}
input,
textarea {
  background-color: #f8f9fa !important;
}
.welcome {
  width: 100%;
  height: 100vh;
}
.welcome-header-container {
  width: 100%;
}
.welcome-header-img {
  margin: 2rem;
}
.welcome-header-img img {
  max-width: 100%;
  height: 50vmin;
  border-radius: 50%;
  object-fit: fill;
}
.welcome-header-text {
  width: 50%;
  /* white-space: nowrap; */
  text-align: left;
  margin: 2rem;
}
.scroll-to-bottom {
  position: fixed;
  width: 20px;
  left: calc(50% - 10px);
  bottom: 30px;
  z-index: 11;
  animation: action 1s infinite alternate;
}
.back-to-top {
  position: fixed;
  display: none;
  width: 30px;
  text-align: center;
  right: 30px;
  bottom: 30px;
  z-index: 11;
  animation: action 1s infinite alternate;
  color: #ffffff;
}
.back-to-top:hover {
  color: #ffffff;
}

.about,
.technologies,
.experience,
.skills,
.projects,
.contact-me {
  padding: 4rem;
}
.about-body-text,
.experience-body,
.skills-body,
.technologies-body,
.projects-body,
.contact-me-body {
  margin-top: 4rem;
}
/* Experience Start*/
.experience {
  font-size: 2.5vmin;
}
.timeline {
  border-left: 1px solid;
  border-color: #ffffff;
  padding-top: 0.5rem;
  padding-left: 1.5rem;
  margin-left: 0.5rem;
}
.experience-container {
  position: relative;
  margin-bottom: 4rem;
}
.timeline-circle {
  position: absolute;
  top: 5px;
  color: #ffffff;
  left: -36.5px;
}
/* Experience End*/

.skills-element {
  display: inline-block;
  opacity: 0.65;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 60vmin;
  text-align: center;
}
.skills-content {
  display: inline-block;
  margin-top: 10%;
  padding: 3%;
  background-color: black;
  text-align: center;
  border-radius: 25px;
  white-space: normal;
  max-height: 100%;
}
/* Slideshow */
.slideshow {
  margin: 0 auto;
  overflow: hidden;
}
.slideshowSlider {
  white-space: nowrap;
  transition: ease 1000ms;
}
/* Buttons */
.slideshowDots {
  margin-top: 2rem;
  text-align: center;
}
.slideshowDot {
  display: inline-block;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  cursor: pointer;
  margin: 1% 0.4% 0;
  background-color: #e9ecef;
}
.slideshowDot.active {
  background-color: #00bbf9;
}

.technology-title {
  margin: 1%;
}
.progress-bar {
  background-color: #00bbf9;
}
.technologies-note {
  text-align: right;
}

.card {
  width: 25rem;
  height: 18rem;
  background-color: #082c44;
  text-align: center;
}
.card img {
  /* opacity: 0.9; */
  width: 100%;
  height: 15rem;
  object-fit: fill;
}
.card-title {
  font-size: 2.5vmin;
}
.card:hover {
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2), 0 4px 20px 0 rgba(0, 0, 0, 0.19);
  cursor: pointer;
  opacity: 1;
}
.modal-content {
  background-color: #0077b6;
}

/* Contact Me starts */
.contant-me-body {
  text-align: center;
}
.btn {
  display: inline-block;
  vertical-align: middle;
  background-color: #082c44;
  width: 15rem;
  color: white;
  padding: 0.375rem 0.75rem;
  text-align: center;
}
.btn:hover {
  background-color: #00bbf9;
}
/* Contact Me Ends */
@keyframes action {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-15px);
  }
}
